.blog .carousel-indicators {
    bottom: -50px;
    padding: 20px 0;
}

/* The colour of the indicators */
.blog .carousel-indicators li {
    background: #a3a3a3;
    border-radius: 7% !important;
    width: 5px !important;
    height: 0px !important;
    display: none;
}

.blog .carousel-indicators .active {
    background: #009688;
}

.carousel-item .col, .col-4 {
    /* width: 128.88px;
    height: 43.39px; */
   text-align: center;
   display: block;
}
