html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    background-image: url("../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover; 
}

.logo-image {
    width: 165px;
    height: 52.5px;
}
.dashboard-button {
    border: 1px solid #009688;
    color: #009688;
    font-size: 1.5rem;
}
.nav-item {
    margin-right: 2rem;
}
.nav-link {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.3;
    /* vertical-align: middle; */
    letter-spacing: -0.01em;
}
.nav-link:hover {
    color: #009688;
}

.banner-content {
    padding: 240px 0 100px;
    width: 50%;
    justify-content: flex-end;
}

.banner-content h1{
    font-size: 4.5rem;
    font-weight: 900;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.banner-content p{
    font-size: 2rem;
    color: #fff;
}
.banner-content button{
    margin-top: 28px;
    font-size: 1.5rem;
    color: white;
    background-color: #009688;
    padding: 1.5rem;
}

#features {
    background-color: #fafafa;
    padding: 100px 0;
}

#features h1{
   font-size: 3rem;
   text-align: center;
   /* font-family: "Nunito Sans", sans-serif; */
   font-family: 'Montserrat', sans-serif;
   /* margin-bottom: 2.8rem; */
}

.feature-card {
    border-radius: 12;
    background-color: white;
    padding: 48px 32px;
    height: 370px;
}

.feature-card:hover {
    background-color: #009688;
    color: white;
}

.feature-card:hover .material-icons{
    background-color: #fff;
}
.card-body h4 {
    margin-bottom: 10px;
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.card-body p {
    font-size: 1.4rem;
    line-height: 1.6;
    font-family: "Nunito Sans", sans-serif;
}

.material-icons {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: #009688;
}
#about {
    padding: 100px 0;
}
#about h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.about-content {
    text-align: justify;
    margin-top: 4rem;
    height: 20rem;
}

#about p {
    font-size: 1.6rem;
    font-family: "Nunito Sans", sans-serif;
}
.about-img {
    text-align: center;
}
.wallet {
    height: 24%;
}
#how {
    padding: 100px 0;
}

#how h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    /* margin-bottom: 2.8rem; */
}

#partners {
    background-color: #fafafa;
    padding: 100px 0;
}

#partners h1 {
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    /* margin-bottom: 2.8rem; */
}

.partner-logo {
    height: 43.39px;
}

.tecni {
    width: 128.88px;
    height: 43.39px;
}

.carousel-inner {
    padding: 40px 0;
}


#notified {
    background-color: #fafafa;
    padding: 100px 0;
}
#notified h1{
    font-size: 3rem;
    text-align: center;
    /* margin-bottom: 2.8rem; */
    font-family: "Montserrat", sans-serif;
 }

 #notified p {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2.8rem;
    font-family: "Nunito Sans", sans-serif;
}

 .form-container {
     width: 60%;
     margin: 0 auto;
 }

 .form-container label {
     font-size: 1.5rem;
 }

 .form-container input{
    height: 5rem;
    font-size: 1.5rem;
}
textarea {
    height: 20rem !important;
    font-size: 1.5rem  !important;
}
.form-container button{
    margin-top: 28px;
    font-size: 1.5rem;
    color: white;
    background-color: #009688;
    padding: 1.5rem;
}

.short-dash {
    width: 10%;
    margin: 0 auto;
    height: .3rem;
    background-color: #009688;
    margin-bottom: 2.8rem;
}
.small-screen {
    display: none;
}

#contact {
    padding: 100px 0;
}
#contact h1{
    font-size: 3rem;
    text-align: center;
    /* margin-bottom: 2.8rem; */
    font-family: "Montserrat", sans-serif;
 }

 #contact p {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2.8rem;
    font-family: "Nunito Sans", sans-serif;
}

#footer {
    background-color: #fafafa;
    padding: 100px 0;
}
#footer ul {
    margin-top: 2rem;
    padding: 0;
}
#footer h5 {
   font-size: 1.8rem;
   font-family: "Montserrat", sans-serif;
}

#footer ul li{
    list-style-type: none;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-family: "Nunito Sans", sans-serif;
}
.footer-icon {
    font-size: 2rem;
    color: #009688;
    margin-right: 1rem;
}
.social-container {
    text-align: center;
}

.social-container p {
   font-size: 1.2rem;
   margin-top: 1rem;
}
.social-icon {
    font-size: 1.7rem;
    margin: 0 1.5rem;
    color: #212529;    
}

.instagram:hover {
   color: rgb(225,48,108);
}

.twitter:hover {
    color: rgb(29,161,242);
}
.facebook:hover {
   color: rgb(60,90,153);
}



#footer a {
    text-decoration: none;
    color: #212529;    
}

#footer a:hover {
    text-decoration: none;
    color: #009688;
}



@media screen and (max-width: 600px) {
    .logo-image {
        width: 165px;
        height: 52.5px;
    }
    .navbar-collapse {
        opacity: 0.9;
    }
    .nav-item {
        margin-bottom: 1.6rem;
    }
    .icon-bar {
        height: .3rem;
        border-radius: 25px;
        background-color: #212529;
        width: 2rem;
        margin: .4rem 0;
    }
    .banner-content {
        padding: 80px 0 50;
        width: 90%;
    }

    .banner-content h1{
        font-size: 3rem;
        font-weight: 900;
    }

    .feature-card {
        margin-bottom: 2rem;
    }

    .form-container {
        width: 90%;
        margin: 0 auto;
    }
    .small-screen {
        display: block;
    }
    .big-screen {
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media screen and (min-width: 600px) {
    body {
        background-color: pink;
    }
}  */