.invoice {
    background: white;
}

.invoice-container {
    border: 1px solid black;
    background: white;
    width: 100%;
    margin: 20px auto;
}

.invoice-content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 100px;
}
.logo-container {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo-image img {
    width: 80%;
}
.logo-container h2 {
  font-weight: 900;
}
.invoice-data {
    display: flex;
    justify-content: space-between;
}

.invoice-data h4:first-child {
    color: green;
    font-weight: 700;
}

.billing-container h3 {
    font-weight: 900;
}


.invoice-table {
    margin: 40px 0;
    width: 100%
}
.invoice-table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .invoice-table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .table-head-no-border {
    border: none;
  }

  
  .invoice-table tr:nth-child(even) {
    background-color: #dddddd;
  }

  .table-row-no-bg {
    background-color: #fff !important;
  }

  .colored-th {
      background-color: green;
      color: white;
  }
  .invoice-notice h4 {
      font-weight: 900;
  }