html {
  font-size: 62.5%;
}
.App {
  /* background-color: rgb(244, 247, 253); */
  font-family: "Nunito Sans", sans-serif;

}
.main-layout {
  background-color: #fafafa;
  height: 100vh;

}
#sider {
  height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.dash-card {
  display: flex;
  align-items: center;
  height: 150px;
  background-color: white;
  border-radius: 5px;
  margin: 10px;
  -webkit-box-shadow: 1px 3px 5px 1px #fff; 
  -moz-box-shadow:    1px 3px 5px 1px #fff;
  box-shadow:         1px 3px 5px 1px #ddd; 
}
.dash-header-h1 {
  font-size: 1.8rem;
}
.dash-card-h1 {
  font-weight: 900;
  font-size: 3rem;
}
.dash-card-h5 {
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #009663;
}
.dash-card-1 {
  border-left: 4px solid #961800;
}
.dash-card-h5-1 {
 color:#961800;
}
.dash-card-2 {
  border-left: 4px solid #009663;
}
.dash-card-h5-2 {
  color: #009663;
}
.dash-card-3 {
  border-left: 4px solid #96007e;
}
.dash-card-h5-3 {
  color: #96007e;
}

.dash-table-container {
  background: white;
  padding: 2%;
  margin-bottom: 5%;
}
thead {
  border: 0;
}
tr:nth-child(even) {
  background-color: #fafafa;
}


.dash-table-title {
  font-size: 1.8rem;
}
#content {
  width: 95%;
  margin: 0 auto;
  padding: 24px 0;
  background-color: #fafafa;
}
.sider {
  background-color: white;
  border-right: 2px solid #e9e9e9;
}
.tag {
  display: flex;
  justify-content: flex-end;
}
.file-input {
  display: none;
}
.upload-container {
  display: flex;
  align-items: center;
}
.upload-box {
  display: flex;
  width: 20%;
  height: 100px;
  background-color: #fafafa;
  border: 1px dashed #ccc;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5% 0 0;
}
.sliders {
  width: 50%;
}
.example {
  color: #1890ff;
  text-decoration: underline;
}
.flex-container {
  display: flex;
  align-items: center;
}
.header {
  background-color: white;
  height: 10vh;
  -webkit-box-shadow: 1px 3px 5px 1px #f2f3f4; 
  -moz-box-shadow:    1px 3px 5px 1px #f2f3f4;
  box-shadow:         1px 3px 5px 1px #f2f3f4; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  border: 3px solid #f2f3f4;
  /* background-color: #0a4d6c; */
}
.header2 {
  background-color: white;
  height: 10vh;
  -webkit-box-shadow: 1px 3px 5px 1px #f2f3f4; 
  -moz-box-shadow:    1px 3px 5px 1px #f2f3f4;
  box-shadow:         1px 3px 5px 1px #f2f3f4; 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  border: 3px solid #f2f3f4;
  /* background-color: #0a4d6c; */
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  background-color: white;
}
.steps-container {
  width: 80%;
  padding: 10px 0;
  margin: 0 auto;
  background-color: white;
}
.step {
  cursor: pointer;
  outline: 0;
}
.img-fluid {
  margin: 5% 0;
}
.img-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.footer-layout {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page-container {
  text-align: center;
  height: 80vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form-container {
  width: 450px; 
  -webkit-box-shadow: 1px 3px 5px 2px #f2f3f4; 
  -moz-box-shadow:    1px 3px 5px 2px #030303;
  box-shadow:         1px 3px 5px 2px #f2f3f4; 
}
.login-form-button {
  width: 100%;
}
.links {
  color: #1890ff;
  cursor: pointer;
}
.link {
  color: #1890ff;
  text-decoration: underline;
  margin-right: 5%;
}
.trade-container {
  width: 80%;
  margin: 10px auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  -webkit-box-shadow: 1px 3px 5px 2px #f2f3f4; 
  -moz-box-shadow:    1px 3px 5px 2px #f2f3f4;
  box-shadow:         1px 3px 5px 2px #f2f3f4;
  height: 20vh;
}


.flex-space-between {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.window {
  padding: 1rem;
  -webkit-box-shadow: 1px 3px 10px 5px #f2f3f4; 
  -moz-box-shadow:    1px 3px 10px 5px #f2f3f4;
  box-shadow:         1px 3px 10px 5px #f2f3f4;
  height: 70vh;
  overflow: scroll;
}

.loan-data {
  border-bottom: 1px solid #ddd;
  margin: 30px 0;
}
.remarks-cont {
  margin: 20px 0;
}
.trade-container-2 {
  width: 80%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border: 2px solid #f4f6f6;
  height: 20vh;
}
.trade-container-right {
  width: 40%;
  border-radius: 4px;
  border: 2px solid #f4f6f6;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.edit-form-container {
  width: 60%;
  margin: 0 auto;
}
.price {
 border: 1px dashed #999;
 padding: 20px;
}
.box-shadow {
  -webkit-box-shadow: 1px 3px 5px 2px #f2f3f4; 
  -moz-box-shadow:    1px 3px 5px 2px #f2f3f4;
  box-shadow:         1px 3px 5px 2px #f2f3f4;
  padding: 20px;
  border-top: 1px solid #f2f3f4;
  margin-bottom: 20px;
}

.sourcing-partner-card {
  position: relative;
  height: 150px;
  cursor: pointer;
}

.selected-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #009688;
}

.add-form {
  max-width: 550px;
  margin: 0 auto;
  background: #fff;
  padding: 20px 15px;
}


.badge-text {
  margin-bottom: 10px;
}

.badge-text h3 {
  margin: 0;
}

.badge-circle {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 10px;
}

.center {
  display: flex;
  justify-content: center;
  text-align: center;
}

.responsive-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 auto;
}
.activity-images-button {
  color: #1890ff;
  cursor: pointer;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

@media (min-width: 576px) { 

}

@media (min-width: 768px) {  }

@media (min-width: 992px) {  }

@media (min-width: 1200px) {  }